import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
const Profile = lazy(()=> import("./pages/Profile"));
const Packages = lazy(()=> import("./pages/Packages"));
const Thankyou = lazy(()=>import("./pages/Thankyou"));
const Forgot = lazy(()=>import("./pages/Forgot"));
const Verify = lazy(()=>import("./pages/Signup/verify"));
const Privacy = lazy(()=>import("./pages/Privacy"));
const Refund = lazy(()=>import("./pages/Privacy/refund"));
const Faq = lazy(()=>import("./pages/Privacy/faq"));

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/privacy_policy" element={<Privacy />} />
        <Route path="/refund_policy" element={<Refund />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </Suspense>
  </Router>
);

export default App;
